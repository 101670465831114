import React from 'react';
import { Nav } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faMedium } from '@fortawesome/free-brands-svg-icons';

const Footer: React.FC = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-content-wrapper">
        <div className="footer-links">
          <Nav.Link className="sub-link" href="/">
            About Us
          </Nav.Link>
          <Nav.Link className="sub-link" href="/operations">
            Operations
          </Nav.Link>
          <Nav.Link className="sub-link" href="/contact">
            Contact
          </Nav.Link>
        </div>
        <div className="footer-social">
          <div className="social-title">Connect</div>
          <div className="footer-icons">
            <a href="https://utahgascorp.medium.com/" className="social-icon">
              <FontAwesomeIcon icon={faMedium} size="lg" />
            </a>
            <a href="https://www.linkedin.com/company/utahgascorp/" className="social-icon">
              <FontAwesomeIcon icon={faLinkedin} size="lg" />
            </a>
          </div>
        </div>
      </div>
      <div className="copyright">@2023 Utah Gas Corp. All Rights Reserved.</div>
    </div>
  );
};

export default Footer;
