import React, { useState } from 'react';
import { Axios, db } from '../firebase/firebaseConfig';
import { Form, Button, Alert } from 'react-bootstrap';

const InitialState = {
  name: '',
  email: '',
  type: '',
  message: '',
};

const ContactForm: React.FC = () => {
  const [formData, setFormData] = useState(InitialState);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const updateInput = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();
    sendEmail();
    setFormData(InitialState);
  };
  const sendEmail = () => {
    if (!formData['type']) {
      formData['type'] = 'Vendor';
    }
    Axios.post('https://us-central1-ugc-website-8fea9.cloudfunctions.net/submit', formData)
      .then((res) => {
        db.collection('emails').add({
          name: formData.name,
          email: formData.email,
          type: formData.type,
          message: formData.message,
          time: new Date(),
        });
        setFormSubmitted(true);
        setFormData(InitialState);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="contact-container">
      <div className=" divider divider-contact" />
      <div className="skeleton-text-content">
        <div className="header-title">Contact Us</div>
      </div>
      <div className="contact-dual">
        <Form className="contact-form" onSubmit={handleSubmit}>
          {formSubmitted ? (
            <div>
              <Alert key="success" variant="success">
                Thank you! We have received your inquiry.
              </Alert>
            </div>
          ) : (
            ''
          )}
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Name</Form.Label>
            <Form.Control
              name="name"
              placeholder="name"
              required={true}
              onChange={updateInput}
              value={formData.name || ''}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              name="email"
              placeholder="email@gmail.com"
              required={true}
              onChange={updateInput}
              value={formData.email || ''}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Select</Form.Label>
            <Form.Control
              name="type"
              as="select"
              onChange={updateInput}
              value={formData.type || 'Vendor'}
            >
              <option>Vendor</option>
              <option>Land</option>
              <option>Owner</option>
              <option>General</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Message</Form.Label>
            <Form.Control
              name="message"
              placeholder="your message here"
              as="textarea"
              rows={3}
              required={true}
              onChange={updateInput}
              value={formData.message || ''}
            />
          </Form.Group>
          <Button type="submit">Submit</Button>
        </Form>
        <div className="offices-wrapper">
          <div className="offices-inner">
            <div className="office-title"> Grand Junction Office </div>
            <div> 760 Horizon Drive, STE 400 </div>
            <div> Grand Junction, Colorado 81506 </div>
          </div>
          <div className="offices-inner">
            <div className="office-title"> Utah Office </div>
            <div> 133 E 1000 N </div>
            <div> Roosevelt, Utah 84066 </div>
          </div>
          <div className="offices-inner">
            <div className="office-title"> Houston Headquarters </div>
            <div> 2727 Kirby Drive STE 30E </div>
            <div> Houston, Texas 77098 </div>
          </div>
          <div className="offices-inner">
            <div className="office-title"> Rangely Office </div>
            <div> 1125 Escalante Drive </div>
            <div> Rangely, Colorado 81648 </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
