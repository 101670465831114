import Props from './Props';

const HomeData: Props = {
  header:
    'Utah Gas Corp produces, gathers, and processes clean burning natural gas and helium on the western slope of the Rockies.',
  highlightPara:
    'We pride ourselves on utilizing technology to operate as cleanly and efficient as possible.',
  paragraphs: [
    'We have 93 highly skilled employees who take pride in the work we do and the communities we are part of. We are dedicated to safety, efficiency, and preserving the beautiful lands we have the privilege of working in daily. We are also active in the communities we call home through sponsorship in local 4H chapters, high school activities, and other community programs. We are proud to represent the western slope and we vow to be responsible stewards of the lands we occupy and play on.',
  ],
};

export default HomeData;
