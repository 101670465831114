import React from 'react';
import logo from '../images/logo.png';
import { Image, Navbar, Nav, NavDropdown } from 'react-bootstrap';

const newNav: React.FC = () => {
  return (
    <Navbar collapseOnSelect expand="lg" bg="light">
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav>
          <NavDropdown title="ABOUT" id="collasible-nav-dropdown">
            <NavDropdown.Item href="/about_us">about us</NavDropdown.Item>
            <NavDropdown.Item href="/management">management</NavDropdown.Item>
            <NavDropdown.Item href="/contact">contact</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href="/operations">OPERATIONS</Nav.Link>
          <Nav.Link href="/environmental">ENVIRONMENTAL</Nav.Link>
          {/* add on click and on mobile */}
          <Image className="logo" src={logo} fluid />
          <Nav.Link href="/community">COMMUNITY</Nav.Link>
          <Nav.Link href="/owner_relations">OWNER RELATIONS</Nav.Link>
          <Nav.Link href="/vendors">VENDORS</Nav.Link>
        </Nav>
      </Navbar.Collapse>
      <Image className="mobile-logo" src={logo} fluid />
    </Navbar>
  );
};
export default newNav;
