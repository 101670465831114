import React from 'react';
import { Button, Card } from 'react-bootstrap';
import acfForm from '../images/pdfs/VendorsACH.pdf'
import changeOfAddress from '../images/pdfs/ChangeOfAddressForm.pdf'
import newVendorPacket from '../images/pdfs/NewVendorPacket.pdf'
import ugcMsaForm from '../images/pdfs/UGCMSAForm.pdf'
import w9Form from '../images/pdfs/W9Form.pdf'
import Accordion from 'react-bootstrap/Accordion';

const openInNewTab = (url: any) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

const Vendors: React.FC = () => {
  return (
    <div>
    <div className="home-component">
      <div className="divider" />
      <div className="vendors-content">
        <div className="header-title">Vendors</div>
        <div className="split-content ">
        <div className="pdfs-container">
        <div className="form-title ">Common Forms</div>
          <Button className="pdf-button" onClick={() => openInNewTab(acfForm)}>
            ACH Form
          </Button>
          <Button className="pdf-button" onClick={() => openInNewTab(changeOfAddress)}>
            Change of Address Form
          </Button>
          <Button className="pdf-button" onClick={() => openInNewTab(newVendorPacket)}>
            New Vendor Packet
          </Button>
          <Button className="pdf-button" onClick={() => openInNewTab(ugcMsaForm)}>
            Master Services Agreement Form
          </Button>
          <Button className="pdf-button" onClick={() => openInNewTab(w9Form)}>
            W9 Form
          </Button>
        </div>
        <div className='faq-section'>
        <div className="form-title ">FAQs</div>
        <div className="faq-container">
        <Accordion className="accordion-override" defaultActiveKey={undefined}>
          <Card>
            <div>
              <Accordion.Toggle as={Button} 
                variant="link" eventKey="0">
                What is OpenInvoice?
              </Accordion.Toggle>
            </div>
            <Accordion.Collapse eventKey="0">
            <div className="faq-answer">
            <ul>
            <li>
            Utah Gas Op Ltd/ UGC Midstream Ltd uses a software program called Openlnvoice to store and process invoices. We prefer and encourage ALL our vendors to submit directly to Openlnvoice as it provides a direct line of communication between Utah Gas Op Ltd/ UGC Midstream and the vendor. As a supplier (vendor) on Openlnvoice, you can see the status of each invoice from start to finish including payment information as soon as it is available. To help expedite the processing of your invoices, please register as a supplier, and submit invoices directly, to register please visit www.openinvoice.com and see attached invoicing instructions for OpenInvoice requirements            </li>
            <li>
            If you are unable to register as a supplier on Open Invoice, please email invoices to invoices@utahgascorp.com and see attached invoicing instructions for email requirements.            </li>
            </ul>
            </div>
                        </Accordion.Collapse>
          </Card>
          <Card>
            <div>
              <Accordion.Toggle as={Button} 
                variant="link" eventKey="1">
                What information is required on Field Tickets/Invoices? 
              </Accordion.Toggle>
            </div>
            <Accordion.Collapse eventKey="1">
            <div className="faq-answer">
          <ul>
            <li>
            Date of actual service performed and/or material delivered
            </li>
            <li>
Description of service(s) performed and/or material(s) delivered
            </li>
            <li>
            Name of Utah Gas Op Ltd/ UGC Midstream Ltd representative who ordered or supervised the work, cost center, and account coding (this is required for invoice to be processed, invoices will be disputed if this is not included)
            </li>
            <li>
            Field ticket attached to invoices
            </li>
            <li>

            The exception is for monthly service rentals and utilities
            </li>
            <li>
            Invoices must be submitted in a timely fashion
            </li>
          </ul>
        </div>
            </Accordion.Collapse>
          </Card>
          <Card>
            <div>
              <Accordion.Toggle as={Button} 
                variant="link" eventKey="2">
                Payment Terms

              </Accordion.Toggle>
            </div>
            <Accordion.Collapse eventKey="2">
            <div className="faq-answer">
            Payment terms are net 30 however when a discount is offered, we can pay at an expedited pace. Please contact ap@utahgascorp.com for more information.
        </div>
            </Accordion.Collapse>
          </Card>
          <Card>
            <div>
              <Accordion.Toggle as={Button} 
                variant="link" eventKey="3">
                Where do I submit forms in open invoice?
              </Accordion.Toggle>
            </div>
            <Accordion.Collapse eventKey="3">
            <div className="faq-answer">
            <ul>
              <li>
              When submitting invoices, please submit to the “Accounts Payable” option in Open Invoice

              </li>
              <li>
              The cost center number is required for submission, this number is provided by the company man that signs the ticket/invoice

                </li>
              </ul>
              </div>
            </Accordion.Collapse>
          </Card>
          <Card>
            <div>
              <Accordion.Toggle as={Button} 
                variant="link" eventKey="4">
                What do I need to complete for a New Vendor Set-Up?
              </Accordion.Toggle>
            </div>
            <Accordion.Collapse eventKey="4">
            <div className="faq-answer">
            <ul>
            Below is a what we need to get you set up as a new vendor.
              <li>
              Complete the documents in the "New Vendor Packet" in the Common Forms section of this page and send to
ap@utahgascorp.com
              </li>
              <li>
              Submit invoices to:www.openinvoice.com (Preferred) or invoices@utahgascorp.com
                </li>
              </ul>
              </div>
            </Accordion.Collapse>
          </Card>
          <Card>
            <div>
              <Accordion.Toggle as={Button} 
                variant="link" eventKey="5">
What do I submit if I will be working in the field?
              </Accordion.Toggle>
            </div>
            <Accordion.Collapse eventKey="5">
            <div className="faq-answer">
            <ul>
            Submit the "New Vendor Packet" seen in the Common forms section to Jamie at ap@utahgascorp.com
If working in the field, submit the following to bbrokaw@utahgascorp.com
              <li>
              Certificate of Insurance 
              </li>
              <li>
              Drug Program Verification Certificate 
                </li>
                <li>
                MSA                
                </li>
                <li>
                Veriforce SSQID                
                </li>
                ***For MSA or Insurance questions email Russ Knight rknight@utahgascorp.com
              </ul>
              </div>
            </Accordion.Collapse>
          </Card>
          <Card>
            <div>
              <Accordion.Toggle as={Button} 
                variant="link" eventKey="6">
1099 Inquiries
              </Accordion.Toggle>
            </div>
            <Accordion.Collapse eventKey="6">
            <div className="faq-answer">
            If you have questions or need a copy of your 1099, please email ap@utahgascorp.com
</div>            
</Accordion.Collapse>
          </Card>
          <Card>
            <div>
              <Accordion.Toggle as={Button}
                variant="link" eventKey="7">
What is a 1099-NEC
              </Accordion.Toggle>
            </div>
            <Accordion.Collapse eventKey="7">
            <div className="faq-answer">
            1099-NEC form reports nonemployee compensation - this will report payments made to independent contractors during the calendar year
</div>
</Accordion.Collapse>
          </Card>
        </Accordion>
        </div>
        </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Vendors;
