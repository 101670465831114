import React from 'react';

import operations from '../../src/images/skeleton-top-header-images/operations.jpeg';
import HomeData from '../data/HomeData';
import bridge from '../../src/images/bridge.jpg';
import mountain from '../../src/images/mountain.png';
import tanks from '../../src/images/tanks.jpg';
import Skeleton from '../components/Skeleton';

const Home: React.FC = () => {
  return (
    <div className="home-elements">
      <Skeleton
        highlightPara={HomeData.highlightPara}
        header={HomeData.header}
        images={[bridge, mountain, tanks]}
        paragraphs={HomeData.paragraphs}
        headerImage={operations}
      />
      {/* VIMEO DISABLED */}
      {/* <iframe
        title="vimeo-iframe"
        className="vimeo-iframe"
        src="https://player.vimeo.com/video/517806270?autoplay=1&loop=1"
        width="800"
        height="700"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      ></iframe> */}
    </div>
  );
};

export default Home;
