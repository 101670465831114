import React from 'react';

interface Props {
  pubDate: string | null;
  title: string;
  description: string;
  link: string;
}

const Blog: React.FC<Props> = ({ pubDate, title, description, link }: Props) => {
  return (
    <div className="blog-wrapper">
      <div className="blog-overview">
        <a className="blog-section" href={link}>
          <div className="blog-date">{pubDate}</div>
        </a>
        <a href={link} className="blog-section">
          <div className="blog-title">{title}</div>
        </a>
        <a href={link} className="blog-section">
          <div className="blog-description">{description}</div>
        </a>
      </div>
    </div>
  );
};

export default Blog;
