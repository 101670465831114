import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import AaronMartinsen from '../../src/images/staff-images/AaronMartinsen.jpg';
import CodyClark from '../../src/images/staff-images/cody_clark.png';
import Russ from '../../src/images/staff-images/russ.png';
import Avatar from '../../src/images/staff-images/Avatar.jpg';

class Management extends Component {
  render() {
    return (
      <div className="management-wrapper">
        <div className="divider divider-contact" />
        <div className="skeleton-text-content">
          <div className="header-title">Management</div>
        </div>
        <div className="people-container">
          <div className="person">
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" className="img-thumbnail" src={Russ} />
              <Card.Body>
                <Card.Title>Russ Knight</Card.Title>
                <Card.Text>President</Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="person">
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" className="img-thumbnail" src={AaronMartinsen} />
              <Card.Body>
                <Card.Title>Aaron Martinsen</Card.Title>
                <Card.Text>Chief Executive Officer</Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="person">
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" className="img-thumbnail" src={CodyClark} />
              <Card.Body>
                <Card.Title>Cody Clark</Card.Title>
                <Card.Text>VP, Commercial Operations</Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="person">
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" className="img-thumbnail" src={Avatar} />
              <Card.Body>
                <Card.Title>Ronnie Plummer</Card.Title>
                <Card.Text>VP, Production</Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="person">
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" className="img-thumbnail" src={Avatar} />
              <Card.Body>
                <Card.Title>Barry Wilkinson</Card.Title>
                <Card.Text>VP, Engineering</Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

export default Management;
