import Props from './Props';

const EnvironmentalData: Props = {
  header: 'Environmental',
  headerPhrase: 'Limiting Our Impact',
  highlightPara:
    'We continually exceed the requirements of the state and federal agencies for operations and environmental restoration. Our operations are conducted with the objective to “leave no trace behind”.',
  paragraphsTitle: 'Our Philosophy',
  paragraphs: [
    'At UGC, we strive to meet and exceed the state and federal requirements to ensure better air quality for all of us. We relentlessly monitor our operations to make sure we are having as little impact as possible on the environment. We protect our water with a wide-range of proven management processes. We use a holistic “life-cycle” approach, from site planning, to drilling, to production, through final reclamation to help insure the land is cared for and restored for future generations.',
  ],
  link: 'https://www.blm.gov/programs/energy-and-minerals/oil-and-gas/reclamation/colorado',
  linkText: 'BLM Reclamation Efforts in Colorado',
};

export default EnvironmentalData;
