import Props from './Props';

const CommunityData: Props = {
  header: 'Community',
  headerPhrase: 'Right Where We Call Home',
  highlightPara:
    'We live where we work so our communities are important to us. Our team members get involved and we feel a sense of responsibility to do more.',
  paragraphsTitle: 'Our Duty',
  paragraphs: [
    'Our communities are fairly small so the opportunity to make a difference can be huge. We support local kids’ school, sports, and 4H programs as well as community events like the “Rangely Roundup”. Some of our members are active in local politics and other volunteer positions serving our community.',
  ],
};

export default CommunityData;
