import React from 'react';
import { Button, Card } from 'react-bootstrap';
import changeOfAddress from '../images/pdfs/COAOwnerRelations.pdf'
import w9Form from '../images/pdfs/W9Form.pdf'
import Accordion from 'react-bootstrap/Accordion';

const openInNewTab = (url: any) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

const OwnerRelations: React.FC = () => {
  return (
    <div>
    <div className="home-component">
      <div className="divider" />
      <div className="OwnerRelations-content">
        <div className="header-title">Owner Relations</div>
        <div className="split-content ">
        <div className="pdfs-container">
        <div className="form-title ">Common Forms</div>
          <Button className="pdf-button" onClick={() => openInNewTab(changeOfAddress)}>
            Change of Address Form
          </Button>
          <Button className="pdf-button" onClick={() => openInNewTab(w9Form)}>
            W9 Form
          </Button>
          <div className="energyLink-iframe">
            <div className="energy-link-title">
          Energylink Login
            </div>
          <div>
          <div className="energyLink-iframe">
          <iframe frameBorder="0" seamless src="https://www.energylink.com/LoginFrame?Region=Us&OpBaId=801632" height="380px" width="350px"></iframe>
          </div>
          </div>
          </div>
        </div>
        <div className='faq-section'>
        <div className="form-title ">FAQs</div>
        <div className="faq-container">
        <Accordion className="accordion-override" defaultActiveKey={undefined}>
          <Card>
            <div>
              <Accordion.Toggle as={Button}
                variant="link" className="accordion-link" eventKey="0">
                How do I update my mailing address?
              </Accordion.Toggle>
            </div>
            <Accordion.Collapse eventKey="0">
            <div className="faq-answer">
            <ul>
            <li>
            Address changes can be submitted via email or mail. Please be sure to execute the Change of Address Form
in its entirety and include your owner number and last 4 digits of your Taxpayer Identification Number (SSN
or EIN).           </li>
            <li>
          <a onClick={() => openInNewTab(changeOfAddress)} className="styled-link">
          Change of Address
        </a>
          </li>
            </ul>
            </div>
                        </Accordion.Collapse>
          </Card>
          <Card>
            <div>
              <Accordion.Toggle as={Button}
                variant="link" className="accordion-link" eventKey="1">
                How do I notify UGC of a transfer or acquisition of an interest?
              </Accordion.Toggle>
            </div>
            <Accordion.Collapse eventKey="1">
            <div className="faq-answer">
          <ul>
            <li>
            Please contact our Owner Relations department via email or phone to notify UGC of ownership changes and
to provide all required recorded conveyance documents.
            </li>
          </ul>
        </div>
            </Accordion.Collapse>
          </Card>
          <Card>
            <div>
              <Accordion.Toggle className="accordion-link" as={Button}
                variant="link" eventKey="2">
                Payment Terms
              </Accordion.Toggle>
            </div>
            <Accordion.Collapse eventKey="2">
            <div className="faq-answer">
              <ul>
                <li>
            Payment terms are net 30 however when a discount is offered, we can pay at an expedited pace. Please contact ap@utahgascorp.com for more information.

                </li>
              </ul>
        </div>
            </Accordion.Collapse>
          </Card>
          <Card>
            <div>
              <Accordion.Toggle className="accordion-link" as={Button}
                variant="link" eventKey="3">
                How do I change or update the name on my account?
              </Accordion.Toggle>
            </div>
            <Accordion.Collapse eventKey="3">
            <div className="faq-answer">
            <ul>
              <li>
              Name changes can be submitted via email or mail in writing with required name change documentation
(marriage license, divorce decree, etc.).
              </li>
              </ul>
              </div>
            </Accordion.Collapse>
          </Card>
          <Card>
            <div>
              <Accordion.Toggle className="accordion-link" as={Button}
                variant="link" eventKey="4">
                What is a division order?
              </Accordion.Toggle>
            </div>
            <Accordion.Collapse eventKey="4">
            <div className="faq-answer">
            <ul>
              <li>
              A division order is a document that lists an owner’s proportional decimal interest in a specific well. This
instrument will include the owner’s decimal interest, interest type, well number and well name.
              </li>
              </ul>
              </div>
            </Accordion.Collapse>
          </Card>
          <Card>
            <div>
              <Accordion.Toggle className="accordion-link" as={Button}
                variant="link" eventKey="5">
What is a W-9 form?
              </Accordion.Toggle>
            </div>
            <Accordion.Collapse eventKey="5">
            <div className="faq-answer">
            <ul>
              <li>
              A W-9 (Request for Taxpayer Identification Number and Certification) form is used to provide your correct
Taxpayer Identification Number to UGC, who is required to file an information return with the IRS to report
royalties paid to you.
              </li>
              <li>
          <a onClick={() => openInNewTab(w9Form)} className="styled-link">
          W-9 Form
        </a>
          </li>
              </ul>
              </div>
            </Accordion.Collapse>
          </Card>
          <Card>
            <div>
              <Accordion.Toggle className="accordion-link" as={Button}
                variant="link" eventKey="6">
Does UGC offer direct deposit for royalty payments?
              </Accordion.Toggle>
            </div>
            <Accordion.Collapse eventKey="6">
            <ul>
              <li>
              Direct deposit is available for royalty payments via EnergyLink. To opt in for ACH direct deposit, please set
up or access your EnergyLink account. For assistance setting up your EnergyLink account or opting in for ACH
direct deposit, please contact <a href="mailto:Support@energylink.com">support@energylink.com</a>.
              </li>
            <li>
              <a href="https://www.energylink.com/help/us_bsp/">
              Electronic Payment - Submit An Electronic Form By Clicking On The Contact Operator Button
            (energylink.com)
              </a>
            </li>
              </ul>
</Accordion.Collapse>
          </Card>
          <Card>
            <div>
              <Accordion.Toggle className="accordion-link" as={Button}
                variant="link" eventKey="7">
Can I view my royalty payment check details online?
              </Accordion.Toggle>
            </div>
            <Accordion.Collapse eventKey="7">
            <ul>
              <li>
              Revenue check details can be accessed via your EnergyLink account.
              </li>

              </ul>
</Accordion.Collapse>
          </Card>
          <Card>
            <div>
              <Accordion.Toggle className="accordion-link" as={Button}
                variant="link" eventKey="8">
Can I access my yearly tax documents for royalty payments online? (EnergyLink)
              </Accordion.Toggle>
            </div>
            <Accordion.Collapse eventKey="8">
            <ul>
              <li>
              Yearly tax documents can be accessed via your EnergyLink account.
              </li>

              </ul>
</Accordion.Collapse>
          </Card>
          <Card>
            <div>
              <Accordion.Toggle className="accordion-link" as={Button}
                variant="link" eventKey="9">
What is a 1099 Form?
              </Accordion.Toggle>
            </div>
            <Accordion.Collapse eventKey="9">
            <ul>
              <li>
              A Form 1099-MISC is used to report various types of income other than wages, tips and salaries. The payor of
oil and gas royalties is required to complete this form. This form will be mailed to interest owners by January
31 of each year.
              </li>

              </ul>
</Accordion.Collapse>
          </Card>
          <Card>
            <div>
              <Accordion.Toggle className="accordion-link" as={Button}
                variant="link" eventKey="10">
What is a Colorado DR 0021W form? What is a Utah TC-675R form?
              </Accordion.Toggle>
            </div>
            <Accordion.Collapse eventKey="10">
            <ul>
              <li>
              A Colorado DR 0021W form is an Oil &amp; Gas Withholding Statement for Severance tax withheld from royalty
payments. This form will be mailed to interest owners by March 1 of each year.
              </li>
<li>
A Utah TC-675R form is a Statement of Utah Tax withheld on Mineral Production Payments. This form will be
mailed to interest owners by January 31 of each year.
</li>
              </ul>
</Accordion.Collapse>
          </Card>
          <Card>
            <div>
              <Accordion.Toggle className="accordion-link" as={Button}
                variant="link" eventKey="11">
Who do I contact regarding Easements, Right-of-Ways, Surface Payments, and other surface related
              </Accordion.Toggle>
            </div>
            <Accordion.Collapse eventKey="11">
            <ul>
              <li>
              Makayla Grant
              <br />
Surface Land Manager
<br />

(970) 693-6023
<br />
<a href="mailto:ownerrelations@utahgascorp.com">

ownerrelations@utahgascorp.com
</a>
              </li>
              </ul>
</Accordion.Collapse>
          </Card>
        </Accordion>
        </div>
        </div>
        </div>
        </div>
      </div>
      <div className='owner-relations-contact-div'>
      <div className="owner-relations-contact-title">
          Contact Info
        </div>
      OwnerRelations@utahgascorp.com
      <br />
      (970) 693-6023
      <br />
      Utah Gas Corp
      <br />
      Attn: Owner Relations
      <br />
      760 Horizon Dr., Ste. 400
      <br />
      Grand Junction, CO 81506
      </div>
    </div>
  );
};

export default OwnerRelations;
