import './App.scss';
import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import BlogSummary from './components/BlogSummary';
import ContactContainer from './containers/ContactContainer';
import EnvironmentalContainer from './containers/EnvironmentalContainer';
import Footer from './components/Footer';
import HomeContainer from './containers/HomeContainer';
import JobsContainer from './containers/JobsContainer';
import ManagementContainer from './containers/ManagementContainer';
import NewNav from './components/NewNav';
import OperationsContainer from './containers/OperationsContainer';
import OwnerRelationsContainer from './containers/OwnerRelationsContainer';
import VendorsContainer from './containers/VendorsContainer';
import CommunityContainer from './containers/CommunityContainer';

const App: () => JSX.Element = () => {
  return (
    <Router>
      <div>
        <NewNav />
        <div>
          <div className="content">
            <Switch>
              <Route exact path="/" component={HomeContainer} />
              <Route exact path="/about_us" component={HomeContainer} />
              <Route path="/contact/" component={ContactContainer} />
              <Route path="/environmental/" component={EnvironmentalContainer} />
              <Route path="/management/" component={ManagementContainer} />
              <Route path="/community/" component={CommunityContainer} />
              <Route path="/operations/" component={OperationsContainer} />
              <Route path="/owner_relations/" component={OwnerRelationsContainer} />
              <Route path="/vendors/" component={VendorsContainer} />
              <Redirect to="/" />
            </Switch>
          </div>
          <BlogSummary />
          <Footer />
        </div>
      </div>
    </Router>
  );
};

export default App;
