import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Blog from '../components/Blog';

const MEDIUM_BLOG_URL =
  'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@utahgas';

const BlogSummary: React.FC = () => {
  const [blogResults, setBlogResults] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(MEDIUM_BLOG_URL)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setBlogResults(result.items);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        },
      );
  }, []);

  const toHumanReadableDate = (date: string) => {
    const d = new Date(date.split(' ')[0]);
    const n = d.toDateString();
    return n;
  };

  const shortenedDescription = (description: string) => {
    const result = description.replace(/<\/?[^>]+(>|$)/g, '').slice(0, 350) + '...';
    return result;
  };

  if (blogResults.length === 0) {
    return <div></div>;
  }

  const shortenedResults: any[] | null =
    blogResults.length > 2 ? [blogResults[0], blogResults[1]] : blogResults;

  return (
    <div className="blog-summary-wrapper">
      <div className="blog-summary-title">What’s Happening at UGC?</div>
      {shortenedResults.map((blog) => (
        <Blog
          key={blog.guid}
          pubDate={blog.pubDate ? toHumanReadableDate(blog.pubDate) : null}
          title={blog.title}
          description={shortenedDescription(blog.description)}
          link={blog.link}
        />
      ))}
      <Button href="https://medium.com/@utahgas" variant="secondary" size="lg">
        View Our Blog
      </Button>
    </div>
  );
};

export default BlogSummary;
