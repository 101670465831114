import React from 'react';
import CommunityData from '../data/CommunityData';
import bridge from '../../src/images/bridge.jpg';
import mountain from '../../src/images/mountain.png';
import tanks from '../../src/images/tanks.jpg';
import Skeleton from '../components/Skeleton';
import horses from '../images/skeleton-top-header-images/IMG_0681.jpg';

const Community: React.FC = () => {
  return (
    <Skeleton
      highlightPara={CommunityData.highlightPara}
      headerPhrase={CommunityData.headerPhrase}
      header={CommunityData.header}
      images={[bridge, mountain, tanks]}
      paragraphs={CommunityData.paragraphs}
      paragraphsTitle={CommunityData.paragraphsTitle}
      showImages
      headerImage={horses}
    />
  );
};

export default Community;
