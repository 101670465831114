import React from 'react';
import { Image, Nav } from 'react-bootstrap';

interface Props {
  images: any;
  showImages?: boolean;
  header: string;
  paragraphs: string[];
  headerImage?: any;
  headerPhrase?: string;
  highlightPara: string;
  paragraphsTitle?: string;
  childrenPara?: React.ReactNode;
  link?: string;
  linkText?: string;
  contactLink?: boolean;
}

const Skeleton: React.FC<Props> = ({
  images,
  showImages,
  header,
  paragraphs,
  headerImage,
  headerPhrase,
  highlightPara,
  paragraphsTitle,
  childrenPara,
  link,
  linkText,
  contactLink,
}: Props) => {
  let currImages: any;
  if (showImages) {
    currImages = (
      <div className="row">
        {images.map((image: any, idx: number) => (
          <div className="col-sm-4" key={idx}>
            <Image className="skeleton-img" src={image} fluid />
          </div>
        ))}
      </div>
    );
  } else {
    ('');
  }

  return (
    <div>
      <div className="skeleton-header-image-container">
        {headerImage ? <Image className="skeleton-header-image" src={headerImage} fluid /> : ''}
      </div>
      <div className="home-component">
        <div className="divider" />
        <div className="skeleton-text-content">
          <div className="header-title">{header}</div>
          <div className="header-phrase">{headerPhrase}</div>
          <div className="highlight-para">{highlightPara}</div>
        </div>
        <div className="paragraphs-title">
          {paragraphsTitle ? paragraphsTitle : 'Our Experience'}
        </div>
        <div className="skeleton-paragraphs-content">
          {paragraphs}
          {contactLink && (
            <Nav.Link href="/contact" className="contact-form-link">
              Contact Form
            </Nav.Link>
          )}
        </div>
        <div>{childrenPara ? childrenPara : null}</div>
        <div>
          {link && (
            <a href={link} target="_blank" rel="noreferrer" className="paragraph-link">
              {linkText}
            </a>
          )}
        </div>
        {currImages}
      </div>
    </div>
  );
};

export default Skeleton;
