import React from 'react';
import bridge from '../../src/images/bridge.jpg';
import mountain from '../../src/images/mountain.png';
import tanks from '../../src/images/tanks.jpg';
import operations from '../../src/images/skeleton-top-header-images/operations.jpeg';
import OperationsData from '../data/OperationsData';

import Skeleton from '../components/Skeleton';

const Operations: React.FC = () => {
  return (
    <Skeleton
      highlightPara={OperationsData.highlightPara}
      headerPhrase={OperationsData.headerPhrase}
      header={OperationsData.header}
      paragraphsTitle={OperationsData.paragraphsTitle}
      paragraphs={OperationsData.paragraphs}
      images={[bridge, mountain, tanks]}
      showImages
      headerImage={operations}
    />
  );
};

export default Operations;
