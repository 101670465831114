import Props from './Props';

const OperationsData: Props = {
  header: 'Operations',
  headerPhrase: 'Innovating for the Future',
  highlightPara:
    'Utah Gas Corp is a private firm strategically focused on natural gas production, gathering, and processing in the Rockies.',
  paragraphsTitle: 'Our Operations',
  paragraphs: [
    'UGC’s talented team of industry experts bring decades of experience focused on operational efficiency. Our long-life, proven reserves coupled with our gathering and processing infrastructure uniquely position us to responsibly develop and operate our existing 400,000+ acres of leasehold. We take pride in optimizing the efficient recovery of our energy and helium resources for the benefit of our growing economy. We are a diversified energy development company with over 1,200 wells across Colorado (Piceance Basin) and Utah (Uinta Basin). We also own and operate over 1,600 miles of gas gathering pipelines and three gas processing plants. We produce and market natural gas, propane, NGLs, condensate, and crude oil from our processing plants located near Rangely, CO, Mack, CO, and in Grand County, UT.',
  ],
};

export default OperationsData;
