import React from 'react';
import EnvironmentalData from '../data/EnvironmentalData';
import bridge from '../../src/images/bridge.jpg';
import mountain from '../../src/images/mountain.png';
import dragonTrail from '../../src/images/skeleton-top-header-images/dragon-trail.JPG';

import tanks from '../../src/images/tanks.jpg';
import Skeleton from '../components/Skeleton';

const Environmental: React.FC = () => {
  return (
    <Skeleton
      highlightPara={EnvironmentalData.highlightPara}
      headerPhrase={EnvironmentalData.headerPhrase}
      header={EnvironmentalData.header}
      images={[bridge, mountain, tanks]}
      paragraphsTitle={EnvironmentalData.paragraphsTitle}
      paragraphs={EnvironmentalData.paragraphs}
      link={EnvironmentalData.link}
      linkText={EnvironmentalData.linkText}
      showImages
      headerImage={dragonTrail}
    />
  );
};

export default Environmental;
